<script>
/***
 Name: edit_item
 Code: sa0ChunLuyu
 Time: 2021/11/5 16:53
 Remark: 委托单表管理
 */
import ltree from '../../../../../components/sa0/ltree/ltree.vue'

export default {
  props: ['info', 'create_do', 'reload', 'content'],
  components: {ltree},
  data() {
    return {
      fixed_content_show: false,
      edit_content: [],
      edit_info: {
        "id": 0,
        "name": "",
        "pid": 0,
        "type": 0,
        "remark": "",
      },
      item_list: [],
      item_id: 0,
      checked: false,
      is_default: false,
      tree: [],
    }
  },
  watch: {
    info: {
      handler() {
        this.mountedDo()
      },
      deep: true
    }
  },
  mounted() {
    this.mountedDo()
  },
  methods: {
    // 树开始
    treeCallback(item) {
      this.task_info = item[0]
      console.log(987, this.task_info)
    },
    makeList(list) {
      return list.map((item) => {
        return {
          'id': Number(item.id),
          "type": item.type,
          "name": item.name,
          "children": item.child.length === 0 ? [] : this.makeList(item.child),
          "content": {
            "pid": item.pid,
          }
        }
      })
    },
    // 获取树列表
    getItemList() {
      this.$sa0.post({
        url: this.$api('获取委托单字段分级列表'),
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.item_list = this.makeList(response.data.list);
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    // 树结束
    mountedDo() {

      this.edit_info = JSON.parse(JSON.stringify(this.info))

      this.edit_content = JSON.parse(JSON.stringify(this.content)).map((item) => {
        item.is_required = Number(item.is_required) === 1
        return item
      })
      console.info('this.edit_info.is_default', this.edit_info.is_default)

      this.is_default = Number(this.edit_info.is_default) === 1

      console.log(JSON.stringify(this.edit_info.is_default))
      this.getItemList()
    },


    addItem(id) {
      this.$sa0.post({
        url: this.$api('根据id获取委托单字段详情'),
        data: {
          id: id,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            console.log(response)
            if (response.data.form_word.type !== 0) {
              let con = response.data.form_word
              switch (con.type) {
                case 1:
                case 5:
                case 6:
                case 7:
                  con.content_turn = con.content;
                  break;
                case 2:
                case 3:
                case 4:
                  con.content_turn = JSON.parse(con.content);
                  break;
              }
              this.edit_content.push({
                "id": 0,
                "template_id": this.info.id,
                "form_word_id": con.id,
                "sort": 0,
                "is_del": 2,
                "is_required": false,
                "form_word": con
              })
            }
            this.item_id = response.data.form_word.id
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    itemClick(e) {
      let id = e[e.length - 1].id ? e[e.length - 1].id : 0
      this.addItem(id)
    },
    createDo() {
      this.$sa0.post({
        url: this.$api('创建委托单模板'),
        data: {
          name: this.edit_info.name,
          pid: this.edit_info.pid,
          type: this.edit_info.type,
          remark: this.edit_info.remark,
          content: this.edit_content,
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.$router.push({
              params: {
                id: response.data.id
              },
              query: {
                edit_type: 0
              }
            })
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    updateDo() {
      console.log(JSON.stringify(JSON.parse(JSON.stringify(this.edit_content)).map((item) => {
        item.is_required = item.is_required ? '1' : '2'
        return item
      })))
      this.$sa0.post({
        url: this.$api('修改委托单模板'),
        data: {
          id: this.edit_info.id,
          name: this.edit_info.name,
          pid: this.edit_info.pid,
          type: this.edit_info.type,
          remark: this.edit_info.remark,
          content: JSON.parse(JSON.stringify(this.edit_content)).map((item) => {
            item.is_required = item.is_required ? '1' : '2'
            return item
          }),
        }
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.reload()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    saveClick() {
      if (!this.edit_info.name || this.edit_info.name === '') return layer.msg('请输入模板名称')
      if (this.info.id === 0) {
        this.createDo()
      } else {
        this.updateDo()
      }
    },

    itemUp(key) {
      let data = this.edit_content[key];
      this.$set(this.edit_content, key, this.edit_content[key - 1])
      this.$set(this.edit_content, key - 1, data)
    },
    itemDown(key) {
      let data = this.edit_content[key];
      this.$set(this.edit_content, key, this.edit_content[key + 1])
      this.$set(this.edit_content, key + 1, data)
    },
    itemDel(key) {
      this.edit_content.splice(key, 1)
    },


// 设置默认模板
    SettemplateClick() {
      this.$sa0.post({
        url: this.$api('Set_template'),
        data: {
          id: this.edit_info.id,
          is_default: this.is_default ? '1' : '2'
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('已设置为默认模板')

          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },


// onChange(e) {
//   console.log(`checked = ${e.target.checked}`);
// },
  }
}
</script>
<template>
  <div>
    <a-row type="flex" style="flex-flow: row">
      <a-col flex="250px">
        <div class="bgc">
          <div class="samplelefttit">
            委托单字段列表
          </div>
          <div>
            <!--                        <a-tree :tree-data="item_list" :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="true" @select="itemClick">-->
            <!--                            <a-icon slot="icon" type="carry-out"/>-->
            <!--                        </a-tree>-->
            <ltree ref="ltreeref" w="230px" ot="only" :dt="0" :it="1" :data="item_list" all="all1" :all_menu="[]"
                   :menu="[]" :callback="itemClick"></ltree>
          </div>
        </div>
      </a-col>
      <a-col flex="auto">
        <!--   创建编辑分类-->
        <div class="createsmaplewrap">

          <a-form-model :label-col="{span: 1}" :wrapper-col="{span: 18}">
            <div>
              <a-form-model-item label="名称">
                <a-input v-model="edit_info.name" style="width: 300px"/>
                <a-checkbox style="margin-left: 20px" :checked="checked" v-model="is_default"
                            @change="SettemplateClick()" v-if="edit_info.id !== 0">
                  设置为默认模板
                </a-checkbox>
              </a-form-model-item>
            </div>

            <a-form-model-item>
              <div class="content_wrapper">
                <div class="input_line_wrapper">
                  <div class="content_item_name">
                    <div class="content_item_title_wrapper">自定义模板内容</div>
                    <div class="text-xs text-red-400">仅供排版，填写无效</div>
                  </div>
                </div>
                <div class="input_line_wrapper" v-for="(item,key) in edit_content">
                  <a-space>

                    <a-checkbox v-model="item.is_required"></a-checkbox>

                    <div class="content_item_name">{{ item.form_word.name }}：</div>
                    <div class="content_item_input_wrapper">
                      <div class="input_line_wrapper" v-if="item.form_word.type === 1">
                        <a-input :placeholder="item.form_word.content"></a-input>
                      </div>
                      <div class="content_item_wrapper" v-if="item.form_word.type === 2">
                        <a-select :default-value="item.form_word.content_turn[0]">
                          <a-select-option :value="i" v-for="(i,k) in item.form_word.content_turn">
                            {{ i }}
                          </a-select-option>
                        </a-select>
                      </div>
                      <div class="content_item_wrapper" v-if="item.form_word.type === 3">
                        <a-checkbox-group>
                          <a-checkbox v-for="(i,k) in item.form_word.content_turn">
                            {{ i }}
                          </a-checkbox>
                        </a-checkbox-group>
                      </div>
                      <div class="content_item_wrapper" v-if="item.form_word.type === 4">
                        <a-radio-group>
                          <a-radio v-for="(i,k) in item.form_word.content_turn">
                            {{ i }}
                          </a-radio>
                        </a-radio-group>
                      </div>
                      <div class="content_item_wrapper" v-if="item.form_word.type === 5">
                        <a-textarea :placeholder="item.form_word.content"></a-textarea>
                      </div>
                      <div class="content_image_item_wrapper" v-if="item.form_word.type === 6">
                        <div class="image_item_wrapper" v-for="(i,k) in Number(item.form_word.content_turn)">
                          <a-icon type="plus"/>
                        </div>
                      </div>
                      <div class="content_image_item_wrapper" v-if="item.form_word.type === 7">
                        <a-date-picker v-if="Number(item.form_word.content_turn) === 0" format="YYYY-MM-DD"/>
                        <a-time-picker v-if="Number(item.form_word.content_turn) === 1"/>
                        <a-date-picker show-time v-if="Number(item.form_word.content_turn) === 2"
                                       format="YYYY-MM-DD HH:mm:ss"/>
                      </div>
                    </div>
                    <div class="content_item_button_wrapper">
                      <a-space>
                        <a-button type="primary" v-if="key !== 0" @click="itemUp(key)">↑
                        </a-button>
                        <a-button type="primary" v-if="key !== edit_content.length - 1" @click="itemDown(key)">
                          ↓
                        </a-button>
                        <a-button type="danger" @click="itemDel(key)">删除</a-button>
                      </a-space>
                    </div>
                  </a-space>
                </div>
                <div class="input_line_wrapper">
                  <div class="content_item_name">
                    <div class="content_item_title_wrapper">固定模板内容</div>
                    <div class="text-xs text-red-400">仅供查看，填写无效</div>
                  </div>
                </div>
                <div v-if="fixed_content_show">
                  <div class="input_line_wrapper">
                    <a-space>
                      <div class="content_item_name">委托单名称：</div>
                      <div class="content_item_input_wrapper">
                        <div class="input_line_wrapper">
                          <a-input></a-input>
                        </div>
                      </div>
                    </a-space>
                  </div>
                  <div class="input_line_wrapper">
                    <a-space>
                      <div class="content_item_name">委托单位名称：</div>
                      <div class="content_item_input_wrapper">
                        <div class="input_line_wrapper">
                          <a-input></a-input>
                        </div>
                      </div>
                    </a-space>
                  </div>
                  <div class="input_line_wrapper">
                    <a-space>
                      <div class="content_item_name">联系人：</div>
                      <div class="content_item_input_wrapper">
                        <div class="input_line_wrapper">
                          <a-input></a-input>
                        </div>
                      </div>
                    </a-space>
                  </div>
                  <div class="input_line_wrapper">
                    <a-space>
                      <div class="content_item_name">电话：</div>
                      <div class="content_item_input_wrapper">
                        <div class="input_line_wrapper">
                          <a-input></a-input>
                        </div>
                      </div>
                    </a-space>
                  </div>
                  <div class="input_line_wrapper">
                    <a-space>
                      <div class="content_item_name">来源日期：</div>
                      <div class="content_item_input_wrapper">
                        <div class="input_line_wrapper">
                          <a-date-picker format="YYYY-MM-DD HH:mm:ss"/>
                        </div>
                      </div>
                    </a-space>
                  </div>
                  <div class="input_line_wrapper">
                    <a-space>
                      <div class="content_item_name">委托单编号：</div>
                      <div class="content_item_input_wrapper">
                        <div class="input_line_wrapper">
                          <a-input></a-input>
                        </div>
                      </div>
                    </a-space>
                  </div>
                  <div class="input_line_wrapper">
                    <a-space>
                      <div class="content_item_name">任务加急：</div>
                      <div class="content_item_input_wrapper">
                        <div class="input_line_wrapper">
                          <a-radio-group>
                            <a-radio>是</a-radio>
                            <a-radio>否</a-radio>
                          </a-radio-group>
                        </div>
                      </div>
                    </a-space>
                  </div>
                </div>
                <div @click="fixed_content_show = !fixed_content_show" class="text-center">
                  {{ fixed_content_show ? '点击隐藏' : '点击展开' }}
                </div>
              </div>
            </a-form-model-item>
            <div class="">
              <a-form-model-item label="备注">
                <a-textarea placeholder="" :rows="4" v-model="edit_info.remark"/>
              </a-form-model-item>
            </div>
            <a-form-item :wrapper-col="{ span: 12, offset: 1 }">
              <a-button type="primary" @click="saveClick()" class="ml-4">
                保存
              </a-button>
            </a-form-item>
          </a-form-model>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<style scoped>
.content_item_title_wrapper {
  font-weight: bold;
  font-size: 18px;
}

.content_item_input_wrapper {
  width: 470px;
}

.content_image_item_wrapper {
  width: 600px;
}

.image_item_wrapper {
  margin: 10px 10px 10px 0;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #5a5e6630;
  background: #5a5e6605;
}

.content_item_name {
  width: 130px;
  text-align: right;
}

.content_item_wrapper {
  width: 400px;
}

.content_wrapper {
  border-radius: 6px;
  border: 1px solid #5a5e6630;
  background: #5a5e6605;
  padding: 10px 30px;
  width: 860px;
}

.input_line_wrapper {
  width: 400px;
  display: inline-block;
}

.bgc {
  margin-right: 20px;
  border: 1px solid #eeeeee;
}

.samplelefttit {
  background: #f9f9f9;
  padding: 10px;
  border-bottom: 1px solid #eeeeee;
  font-weight: bold
}

.button_wrapper {
  height: 40px;
  width: 850px;
  text-align: right;
}

.edit_title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.createsmaplewrap {
  width: 1000px;
}
</style>
