<template>
  <!--添加分类-->
  <!-- 创建、编辑分类 -->
  <div class="createsmaplewrap1">
    <div>
      <div v-if="item_info">
        <CreatItem :create_do="create_do" :reload="reload" :info="item_info" :content="template_content"></CreatItem>
      </div>
    </div>
  </div>
</template>
<script>
import CreatItem from "./creatitem/creatitem.vue";

export default {
  components: {CreatItem},
  props: ["reload"],
  name: "item",
  data() {
    return {
      item_info: false,
      method_id: 0,
      item_type: 0,
      item_type1: 0,
      create_do: 1,
      formInline: {},
      labelCol: {span: 4},
      wrapperCol: {span: 14},

    };
  },
  watch: {
    $route: {
      handler() {
        this.mountedDo();
      },
      deep: true,
    },
  },
  mounted() {
    this.mountedDo();
  },
  methods: {

    mountedDo() {
      this.item_type = 0;
      this.item_type1 = 0;
    },
    getMethodInfo(id) {
      this.method_id = id
      this.$sa0
          .post({
            url: this.$api("根据id获取委托单详情"),
            data: {
              id: id,
            },
          })
          .then((response) => {
            this.$sa0.response({
              response: response.data,
              then: (response) => {
                let info = response.data.template;
                this.item_info = info;
                console.log(111, this.item_info)
                this.item_type = Number(info.type);
                this.create_do = 0;
                setTimeout(() => {
                  this.create_do = 1;
                })
                this.template_content = response.data.template_content.map((item) => {
                  if (item.form_word.type !== 0) {
                    let con = item.form_word
                    switch (con.type) {
                      case 1:
                      case 5:
                      case 6:
                      case 7:
                        con.content_turn = con.content;
                        break;
                      case 2:
                      case 3:
                      case 4:
                        con.content_turn = JSON.parse(con.content);
                        break;
                    }
                    item.form_word = con
                    return item
                  }
                })
              },
              error: (response) => {
                layer.msg(response.message);
              },
            });
          });
    },
  },
};
</script>

<style scoped>
.createsmapletit {
  font-size: 20px;
  font-weight: bold;
}

.ml20 {
  margin-left: 20px;
}

.mt20 {
  margin-top: 20px;
}

.w200 {
  width: 200px;
}

.button_wrapper {
  position: absolute;
  width: 1000px;
  text-align: right;
  top: 40px;
  text-align: left;
  background: #fafafa;
  padding: 10px 20px;
}

.edit_table_wrapper {
  position: absolute;
  left: 280px;
  right: 10px;
  bottom: 0;
  top: 0;
}

.creatlist {
  margin-top: 100px
}
</style>
